#root {
  background-color: rgb(255, 243, 223);
}

.pokemon {
  display: inline-block;
  margin: 10px;
  padding: 8px;
  padding-top: 3px;
  border: 1px solid black;
  border-radius: 5px;
  text-align: center;
  box-shadow: -1px 1px 7px black;
  cursor: pointer;
}

.pokemon img {
  display: block;
  margin: 0 auto;
  width: 96px;
  height: 96px;
}

.info {
  margin-top: 5px;
  text-transform: capitalize;
}

.types {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.type-styles {
  display: inline-block;
  width: 80px;
  margin: 5px;
  padding: 5px;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  color: black;
}

h1 {
  text-align: center;
  font-family: "Lobster", sans-serif;
  font-size: 50px;
  margin: 3px;
}

h3 {
  margin: 5px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
}

.sections {
  background-color: rgba(255, 213, 145, 0.493);
  border: 2px solid black;
  border-radius: 20px;
  margin: 15px;
  padding: 10px;
  box-shadow: -1px 1px 7px black;
}

.team {
  height: fit-content;
  top: 15px;
  background-color: rgb(255, 213, 145);
}

.pokelist {
  margin-bottom: 30px;
  min-height: 120vh;
}

.accordion-content {
  background-color: rgb(255, 213, 145);
}

.accordion-title {
  background-color: rgb(255, 213, 145);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.accordion-item {
  background-color: rgb(255, 213, 145);
  border: none;
}

@media (max-width: 1199.97px) {
  .accordion-content {
    max-height: 237px;
    overflow-y: auto;
  }
}

.typeButtons {
  margin: 10px;
}

.accordion-content {
  max-height: 75vh;
  overflow-y: auto;
}
